import restaurant from "./images/olivia.jpeg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/COPII.jpg";
import img_card_nasi from "./images/COPII.jpg";
import imgheadermb from "./images/f3m.png";
import imgheader from "./images/f3.png";
import imgheadermiini from "./images/fo1.jpeg";
import imgheadermiinimb from "./images/fo1.jpeg";
import logo from './images/logo.png'



const data = {
    introData: [{
        copilul: "",
        familia: "familia Zubco",
        logo: logo,
        tata: "",
        mama: "Marcela",
        data: "",
        data_confirmare: "2 iulie 2023",
        savedata: "",
        savedata1: "",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "zubcomarcela01@gmail.com", 
       tel: "+33656791215",
       phone: "tel:+33656791215",
       viber: "viber://chat?number=%2B33656791215",
       whatsapp: "https://wa.me/+33656791215",
       messenger: "https://www.messenger.com/t/cimbir.marcela",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Voyage, sala Olivia",
            data: "21 iulie 2024, duminică, ora 16:00",
            adress: "R3, Ialoveni",
            harta: "https://maps.app.goo.gl/ue6cNDnNHTGYfjZf6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10892.421376998094!2d28.7492821!3d46.9596202!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97f9cf6ab2093%3A0xd4545e11fc7d2e95!2zU2FsYSBkZSBOdW7Im2kg4oCeVm95YWdl4oCd!5e0!3m2!1sro!2s!4v1717843551188!5m2!1sro!2s"  
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare !!!",
          
            message: "Pentru că ne este dor de voi,  ",
            message1:'Pentru că s-au adunat mai multe motive, ',
            message2:"Demne de o MEGA Sărbătoare,",
            message3:'Am zis că....Hai s-o facem și pe asta: ',
            message4:"Petrecerea TRIO PARTY!!!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_card_nasi,
            title1: "Invitație",
            title2: "Cumetria Dxxx",
            title3: "Aniversarea și Cumetria Sxxx",
            message: "Pentru că ne este dor de voi 🙂    ",
            message1:'Pentru că s-au adunat mai multe motive demne de o MEGA Sărbătoare,',
            message2:"Am zis că... HAI s'o facem și pe asta - Perecerea URȘILOR!🙂 ",

        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title1: 'Vă așteptăm, cu mic și mare',
            title2: "Cu mult drag și încântare!",
            title3: "Cu muzică și voie bună,",
            message: " Ne vom distra, de minune!",  
        }
    ],

}

export default data;