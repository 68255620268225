
import React from "react"
import ImgInvitatie from "./ImgInvitatie";

import "../css/ImgInvitatie.css"
import background from "../images/fi.png"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >

            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4" >
                <div className="card p-0 overflow-hidden h-100 shadow" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>


                    <div class="card-body">
                        
                            <h1 style={{ color: 'grey' }}>Invitație</h1>
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />

                            </h5>
                            <h1 style={{ fontSize: window.innerWidth >= 500 ? '30px' : '20px', color: 'green', padding: '20px'}}>
                            Zile trec și ani la rînd <br/>
Tot la voi ne duce gândul. <br/>
Noi sântem 3 avem gusturi diferite, <br/>
dar o dorință unică. <br/>
Nimic mai dorit decît o îmbrățișare <br/>
a celor dragi pe care i-am văzut <br/>
și pe care nu i-am văzut niciodată. <br/>
Am vrea nespus de mult să ne strângem <br/>
laolaltă căci pentru o rețetă condimentată <br/>
cu voie bună ne lipsesc cele mai dragi <br/>
și importante persoane, Voi! <br/>
Așa că nu vă faceți alte planuri <br/>
și nu uitați că copiilor nu trebuie să le refuzați. <br/>
Ne veți onora cu prezența la petrecerea <br/>
organizată în cinstea noastră TRIO PARTY <br/>
în localul <b>”Voyage, sala Olivia”</b>, R3 - Ialoveni, <br/>
duminică, <b>21 iulie, ora 16:00</b><br/>
Nu ratați evenimentul, <br/>
vă așteptăm cu drag!<br/>
                            </h1>








                      

                       
                        <div id="gol"></div>








                        <h4 class="card-footer" style={{ color: 'green' }}>
                        
                            </h4>

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;